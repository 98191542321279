<template>
  <div class="audit-log">
    <dashboard-layout hideWeekDay>
      <template #header>
        <h4 class="p-0 mb-0 align-self-center">Audit Log</h4>
      </template>
      <template #body>
        <audit-log-card-list
          :team="activeTeam"
          :loading="loadingAuditLogFilters || loadingSimulationTeams || loadingSimulationUsers"
        ></audit-log-card-list>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import { initializeTeam } from '@/utils/initialization'
import { managementMixin } from '@/mixins/managementMixin'
import AuditLogCardList from '@/components/Dashboard/AuditLogCardList.vue'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import TeamSelector from '@/components/Control/TeamSelector.vue'
export default {
  components: {
    AuditLogCardList,
    DashboardLayout,
    TeamSelector
  },
  mixins: [helperMixin, managementMixin],
  data() {
    return {
      activeTeam: initializeTeam(),
      activeTeamIndex: 0,
      teams: []
    }
  },
  methods: {
    ...mapActions(['fetchAuditLogFilters']),
    ...mapMutations(['setCurrentSimulationTeam']),
    async getTeams() {
      await this.getSimulationTeams(this.currentSimulation.id)
      this.teams = await _.cloneDeep(this.simulationTeams)

      if (this.teams.length > 0) {
        const simulationTeam = this.currentSimulationTeam
          ? this.currentSimulationTeam
          : this.sortedSimulationTeams[this.activeTeamIndex]
        this.onSimulationTeamChange(simulationTeam)
      }
    },
    async onSimulationTeamChange(item) {
      this.activeTeam = item
      this.setCurrentSimulationTeam(item)
    }
  },
  computed: {
    ...mapGetters(['currentSimulationTeam']),
    hasOneSimulationTeam() {
      return this.teams.length === 1
    },
    sortedSimulationTeams() {
      return this.teams.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    }
  },
  async created() {
    await this.fetchAuditLogFilters()
  },
  watch: {
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          this.activeTeam = _.cloneDeep(newValue)
          await this.getSimulationUsers()
        } else {
          this.activeTeam = initializeTeam()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
